/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateOrder = /* GraphQL */ `subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onCreateOrder(filter: $filter) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderSubscriptionVariables,
  APITypes.OnCreateOrderSubscription
>;
export const onUpdateOrder = /* GraphQL */ `subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onUpdateOrder(filter: $filter) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderSubscriptionVariables,
  APITypes.OnUpdateOrderSubscription
>;
export const onDeleteOrder = /* GraphQL */ `subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
  onDeleteOrder(filter: $filter) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderSubscriptionVariables,
  APITypes.OnDeleteOrderSubscription
>;
export const onCreateProduct = /* GraphQL */ `subscription OnCreateProduct($filter: ModelSubscriptionProductFilterInput) {
  onCreateProduct(filter: $filter) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      senderAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductSubscriptionVariables,
  APITypes.OnCreateProductSubscription
>;
export const onUpdateProduct = /* GraphQL */ `subscription OnUpdateProduct($filter: ModelSubscriptionProductFilterInput) {
  onUpdateProduct(filter: $filter) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      senderAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductSubscriptionVariables,
  APITypes.OnUpdateProductSubscription
>;
export const onDeleteProduct = /* GraphQL */ `subscription OnDeleteProduct($filter: ModelSubscriptionProductFilterInput) {
  onDeleteProduct(filter: $filter) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      senderAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductSubscriptionVariables,
  APITypes.OnDeleteProductSubscription
>;
export const onCreateShipment = /* GraphQL */ `subscription OnCreateShipment($filter: ModelSubscriptionShipmentFilterInput) {
  onCreateShipment(filter: $filter) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateShipmentSubscriptionVariables,
  APITypes.OnCreateShipmentSubscription
>;
export const onUpdateShipment = /* GraphQL */ `subscription OnUpdateShipment($filter: ModelSubscriptionShipmentFilterInput) {
  onUpdateShipment(filter: $filter) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateShipmentSubscriptionVariables,
  APITypes.OnUpdateShipmentSubscription
>;
export const onDeleteShipment = /* GraphQL */ `subscription OnDeleteShipment($filter: ModelSubscriptionShipmentFilterInput) {
  onDeleteShipment(filter: $filter) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteShipmentSubscriptionVariables,
  APITypes.OnDeleteShipmentSubscription
>;
export const onCreateTour = /* GraphQL */ `subscription OnCreateTour($filter: ModelSubscriptionTourFilterInput) {
  onCreateTour(filter: $filter) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTourSubscriptionVariables,
  APITypes.OnCreateTourSubscription
>;
export const onUpdateTour = /* GraphQL */ `subscription OnUpdateTour($filter: ModelSubscriptionTourFilterInput) {
  onUpdateTour(filter: $filter) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTourSubscriptionVariables,
  APITypes.OnUpdateTourSubscription
>;
export const onDeleteTour = /* GraphQL */ `subscription OnDeleteTour($filter: ModelSubscriptionTourFilterInput) {
  onDeleteTour(filter: $filter) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTourSubscriptionVariables,
  APITypes.OnDeleteTourSubscription
>;
export const onCreateContractor = /* GraphQL */ `subscription OnCreateContractor(
  $filter: ModelSubscriptionContractorFilterInput
) {
  onCreateContractor(filter: $filter) {
    id
    number
    name
    billing_rate
    billing_max
    senderAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContractorSubscriptionVariables,
  APITypes.OnCreateContractorSubscription
>;
export const onUpdateContractor = /* GraphQL */ `subscription OnUpdateContractor(
  $filter: ModelSubscriptionContractorFilterInput
) {
  onUpdateContractor(filter: $filter) {
    id
    number
    name
    billing_rate
    billing_max
    senderAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContractorSubscriptionVariables,
  APITypes.OnUpdateContractorSubscription
>;
export const onDeleteContractor = /* GraphQL */ `subscription OnDeleteContractor(
  $filter: ModelSubscriptionContractorFilterInput
) {
  onDeleteContractor(filter: $filter) {
    id
    number
    name
    billing_rate
    billing_max
    senderAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContractorSubscriptionVariables,
  APITypes.OnDeleteContractorSubscription
>;
