/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    typename
    orderNumber
    date_created
    date_paid
    total
    cart_tax
    total_tax
    discount_total
    discount_tax
    shipping_total
    shipping_tax
    fees {
      name
      amount
      total
      total_tax
      __typename
    }
    weight
    customer {
      first_name
      last_name
      dhl_postNumber
      dhl_lockerId
      street
      house_number
      zip
      city
      country
      email
      phone
      company
      add
      __typename
    }
    shipping_method
    delivery_date
    status
    state
    source
    in_progressAt
    finishedAt
    updatedAt
    createdAt
    retoure_shipping_costs
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    position
    typename
    name
    sku
    product_id
    variation_id
    quantity
    length
    width
    height
    area
    weight
    categories
    variations
    options
    extraOptions {
      name
      value
      imagesp
      price
      __typename
    }
    tasks
    currentTask
    contractor_currentTask
    productContractorId
    contractor {
      id
      number
      name
      billing_rate
      billing_max
      senderAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    status
    contractor_status
    shipping_label_printed
    orderNumber
    order {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    productOrderId
    backoffice_note
    total_before_discount
    total_net_price
    total_tax
    nipsild_fixed_costs
    delivery_time
    delivery_date
    shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    tour {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    declinedTourDates
    billed_amount
    customer_request_approved
    in_progressAt
    ready_to_packAt
    finishedAt
    updatedAt
    billedAt
    recievedReturnAt
    reklaOrderNumber
    retoure_type
    retoure_reason
    retoure_manufacturing_fee
    retoure_refund_amount
    retoure_refund_contractors_share
    retoure_shipment {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    createdAt
    orderProductsId
    tourProductsId
    productShipmentId
    productRetoure_shipmentId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const getShipment = /* GraphQL */ `query GetShipment($id: ID!) {
  getShipment(id: $id) {
    id
    typename
    length
    width
    height
    gurtMass
    weight
    serviceProvider
    costs
    calculatedCosts
    shippedAt
    edited
    parcelNumber
    trackID
    suggest_splitting
    overrule_splitting_user
    labelData {
      base64
      format
      __typename
    }
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetShipmentQueryVariables,
  APITypes.GetShipmentQuery
>;
export const listShipments = /* GraphQL */ `query ListShipments(
  $filter: ModelShipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listShipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListShipmentsQueryVariables,
  APITypes.ListShipmentsQuery
>;
export const getTour = /* GraphQL */ `query GetTour($id: ID!) {
  getTour(id: $id) {
    id
    typename
    date
    products {
      items {
        id
        position
        typename
        name
        sku
        product_id
        variation_id
        quantity
        length
        width
        height
        area
        weight
        categories
        variations
        options
        extraOptions {
          name
          value
          imagesp
          price
          __typename
        }
        tasks
        currentTask
        contractor_currentTask
        productContractorId
        contractor {
          id
          number
          name
          billing_rate
          billing_max
          createdAt
          updatedAt
          __typename
        }
        status
        contractor_status
        shipping_label_printed
        orderNumber
        order {
          id
          typename
          orderNumber
          date_created
          date_paid
          total
          cart_tax
          total_tax
          discount_total
          discount_tax
          shipping_total
          shipping_tax
          weight
          shipping_method
          delivery_date
          status
          state
          source
          in_progressAt
          finishedAt
          updatedAt
          createdAt
          retoure_shipping_costs
          __typename
        }
        productOrderId
        backoffice_note
        total_before_discount
        total_net_price
        total_tax
        nipsild_fixed_costs
        delivery_time
        delivery_date
        shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        tour {
          id
          typename
          date
          deliveryNotificationSentTo
          info
          driver
          calculated_shipping_costs
          createdAt
          updatedAt
          __typename
        }
        declinedTourDates
        billed_amount
        customer_request_approved
        in_progressAt
        ready_to_packAt
        finishedAt
        updatedAt
        billedAt
        recievedReturnAt
        reklaOrderNumber
        retoure_type
        retoure_reason
        retoure_manufacturing_fee
        retoure_refund_amount
        retoure_refund_contractors_share
        retoure_shipment {
          id
          typename
          length
          width
          height
          gurtMass
          weight
          serviceProvider
          costs
          calculatedCosts
          shippedAt
          edited
          parcelNumber
          trackID
          suggest_splitting
          overrule_splitting_user
          updatedAt
          createdAt
          __typename
        }
        createdAt
        orderProductsId
        tourProductsId
        productShipmentId
        productRetoure_shipmentId
        __typename
      }
      nextToken
      __typename
    }
    deliveryNotificationSentTo
    info
    driver
    calculated_shipping_costs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTourQueryVariables, APITypes.GetTourQuery>;
export const listTours = /* GraphQL */ `query ListTours(
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListToursQueryVariables, APITypes.ListToursQuery>;
export const getContractor = /* GraphQL */ `query GetContractor($id: ID!) {
  getContractor(id: $id) {
    id
    number
    name
    billing_rate
    billing_max
    senderAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    pickupAddress {
      name
      street
      house_number
      zip
      city
      country
      email
      phone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContractorQueryVariables,
  APITypes.GetContractorQuery
>;
export const listContractors = /* GraphQL */ `query ListContractors(
  $filter: ModelContractorFilterInput
  $limit: Int
  $nextToken: String
) {
  listContractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      number
      name
      billing_rate
      billing_max
      senderAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContractorsQueryVariables,
  APITypes.ListContractorsQuery
>;
export const ordersByUpdateDate = /* GraphQL */ `query OrdersByUpdateDate(
  $typename: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByUpdateDate(
    typename: $typename
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersByUpdateDateQueryVariables,
  APITypes.OrdersByUpdateDateQuery
>;
export const ordersByShippingMethod = /* GraphQL */ `query OrdersByShippingMethod(
  $shipping_method: String!
  $state: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByShippingMethod(
    shipping_method: $shipping_method
    state: $state
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersByShippingMethodQueryVariables,
  APITypes.OrdersByShippingMethodQuery
>;
export const ordersByFinishingState = /* GraphQL */ `query OrdersByFinishingState(
  $state: OrderState!
  $delivery_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByFinishingState(
    state: $state
    delivery_date: $delivery_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersByFinishingStateQueryVariables,
  APITypes.OrdersByFinishingStateQuery
>;
export const ordersByFinishingStateByCreateDate = /* GraphQL */ `query OrdersByFinishingStateByCreateDate(
  $state: OrderState!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByFinishingStateByCreateDate(
    state: $state
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typename
      orderNumber
      date_created
      date_paid
      total
      cart_tax
      total_tax
      discount_total
      discount_tax
      shipping_total
      shipping_tax
      fees {
        name
        amount
        total
        total_tax
        __typename
      }
      weight
      customer {
        first_name
        last_name
        dhl_postNumber
        dhl_lockerId
        street
        house_number
        zip
        city
        country
        email
        phone
        company
        add
        __typename
      }
      shipping_method
      delivery_date
      status
      state
      source
      in_progressAt
      finishedAt
      updatedAt
      createdAt
      retoure_shipping_costs
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersByFinishingStateByCreateDateQueryVariables,
  APITypes.OrdersByFinishingStateByCreateDateQuery
>;
export const itemsByUpdateDate = /* GraphQL */ `query ItemsByUpdateDate(
  $typename: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByUpdateDate(
    typename: $typename
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemsByUpdateDateQueryVariables,
  APITypes.ItemsByUpdateDateQuery
>;
export const itemsByCurrentTask = /* GraphQL */ `query ItemsByCurrentTask(
  $currentTask: String!
  $delivery_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByCurrentTask(
    currentTask: $currentTask
    delivery_date: $delivery_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemsByCurrentTaskQueryVariables,
  APITypes.ItemsByCurrentTaskQuery
>;
export const itemsByContractorTask = /* GraphQL */ `query ItemsByContractorTask(
  $contractor_currentTask: String!
  $delivery_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByContractorTask(
    contractor_currentTask: $contractor_currentTask
    delivery_date: $delivery_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemsByContractorTaskQueryVariables,
  APITypes.ItemsByContractorTaskQuery
>;
export const finishedItemsByStatus = /* GraphQL */ `query FinishedItemsByStatus(
  $status: ProductStatus!
  $finishedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  finishedItemsByStatus(
    status: $status
    finishedAt: $finishedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FinishedItemsByStatusQueryVariables,
  APITypes.FinishedItemsByStatusQuery
>;
export const ongoingItemsByStatus = /* GraphQL */ `query OngoingItemsByStatus(
  $status: ProductStatus!
  $delivery_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  ongoingItemsByStatus(
    status: $status
    delivery_date: $delivery_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OngoingItemsByStatusQueryVariables,
  APITypes.OngoingItemsByStatusQuery
>;
export const finishedItemsByContractorByStatus = /* GraphQL */ `query FinishedItemsByContractorByStatus(
  $contractor_status: String!
  $finishedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  finishedItemsByContractorByStatus(
    contractor_status: $contractor_status
    finishedAt: $finishedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FinishedItemsByContractorByStatusQueryVariables,
  APITypes.FinishedItemsByContractorByStatusQuery
>;
export const ongoingItemsByContractorByStatus = /* GraphQL */ `query OngoingItemsByContractorByStatus(
  $contractor_status: String!
  $delivery_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  ongoingItemsByContractorByStatus(
    contractor_status: $contractor_status
    delivery_date: $delivery_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OngoingItemsByContractorByStatusQueryVariables,
  APITypes.OngoingItemsByContractorByStatusQuery
>;
export const itemsByOrderNumber = /* GraphQL */ `query ItemsByOrderNumber(
  $orderNumber: String!
  $contractor_status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  itemsByOrderNumber(
    orderNumber: $orderNumber
    contractor_status: $contractor_status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      position
      typename
      name
      sku
      product_id
      variation_id
      quantity
      length
      width
      height
      area
      weight
      categories
      variations
      options
      extraOptions {
        name
        value
        imagesp
        price
        __typename
      }
      tasks
      currentTask
      contractor_currentTask
      productContractorId
      contractor {
        id
        number
        name
        billing_rate
        billing_max
        senderAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        pickupAddress {
          name
          street
          house_number
          zip
          city
          country
          email
          phone
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      contractor_status
      shipping_label_printed
      orderNumber
      order {
        id
        typename
        orderNumber
        date_created
        date_paid
        total
        cart_tax
        total_tax
        discount_total
        discount_tax
        shipping_total
        shipping_tax
        fees {
          name
          amount
          total
          total_tax
          __typename
        }
        weight
        customer {
          first_name
          last_name
          dhl_postNumber
          dhl_lockerId
          street
          house_number
          zip
          city
          country
          email
          phone
          company
          add
          __typename
        }
        shipping_method
        delivery_date
        status
        state
        source
        in_progressAt
        finishedAt
        updatedAt
        createdAt
        retoure_shipping_costs
        products {
          nextToken
          __typename
        }
        __typename
      }
      productOrderId
      backoffice_note
      total_before_discount
      total_net_price
      total_tax
      nipsild_fixed_costs
      delivery_time
      delivery_date
      shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      tour {
        id
        typename
        date
        products {
          nextToken
          __typename
        }
        deliveryNotificationSentTo
        info
        driver
        calculated_shipping_costs
        createdAt
        updatedAt
        __typename
      }
      declinedTourDates
      billed_amount
      customer_request_approved
      in_progressAt
      ready_to_packAt
      finishedAt
      updatedAt
      billedAt
      recievedReturnAt
      reklaOrderNumber
      retoure_type
      retoure_reason
      retoure_manufacturing_fee
      retoure_refund_amount
      retoure_refund_contractors_share
      retoure_shipment {
        id
        typename
        length
        width
        height
        gurtMass
        weight
        serviceProvider
        costs
        calculatedCosts
        shippedAt
        edited
        parcelNumber
        trackID
        suggest_splitting
        overrule_splitting_user
        labelData {
          base64
          format
          __typename
        }
        updatedAt
        createdAt
        __typename
      }
      createdAt
      orderProductsId
      tourProductsId
      productShipmentId
      productRetoure_shipmentId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemsByOrderNumberQueryVariables,
  APITypes.ItemsByOrderNumberQuery
>;
export const shipmentsByUpdateDate = /* GraphQL */ `query ShipmentsByUpdateDate(
  $typename: String!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelShipmentFilterInput
  $limit: Int
  $nextToken: String
) {
  shipmentsByUpdateDate(
    typename: $typename
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typename
      length
      width
      height
      gurtMass
      weight
      serviceProvider
      costs
      calculatedCosts
      shippedAt
      edited
      parcelNumber
      trackID
      suggest_splitting
      overrule_splitting_user
      labelData {
        base64
        format
        __typename
      }
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ShipmentsByUpdateDateQueryVariables,
  APITypes.ShipmentsByUpdateDateQuery
>;
export const toursByDate = /* GraphQL */ `query ToursByDate(
  $typename: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTourFilterInput
  $limit: Int
  $nextToken: String
) {
  toursByDate(
    typename: $typename
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      typename
      date
      products {
        items {
          id
          position
          typename
          name
          sku
          product_id
          variation_id
          quantity
          length
          width
          height
          area
          weight
          categories
          variations
          options
          tasks
          currentTask
          contractor_currentTask
          productContractorId
          status
          contractor_status
          shipping_label_printed
          orderNumber
          productOrderId
          backoffice_note
          total_before_discount
          total_net_price
          total_tax
          nipsild_fixed_costs
          delivery_time
          delivery_date
          declinedTourDates
          billed_amount
          customer_request_approved
          in_progressAt
          ready_to_packAt
          finishedAt
          updatedAt
          billedAt
          recievedReturnAt
          reklaOrderNumber
          retoure_type
          retoure_reason
          retoure_manufacturing_fee
          retoure_refund_amount
          retoure_refund_contractors_share
          createdAt
          orderProductsId
          tourProductsId
          productShipmentId
          productRetoure_shipmentId
          __typename
        }
        nextToken
        __typename
      }
      deliveryNotificationSentTo
      info
      driver
      calculated_shipping_costs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ToursByDateQueryVariables,
  APITypes.ToursByDateQuery
>;
export const contractorByNumber = /* GraphQL */ `query ContractorByNumber(
  $number: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContractorFilterInput
  $limit: Int
  $nextToken: String
) {
  contractorByNumber(
    number: $number
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      number
      name
      billing_rate
      billing_max
      senderAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      pickupAddress {
        name
        street
        house_number
        zip
        city
        country
        email
        phone
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContractorByNumberQueryVariables,
  APITypes.ContractorByNumberQuery
>;
