import Drawing from 'dxf-writer'
import { Product } from '../API';
import saveAs from 'file-saver';

export function createDXF(item: Product) {
    if (!item.length || !item.width) return
    // dispatch(setItemsError(''));
    const length = item.length * 10
    const width = item.width * 10
    const cornerRadius = item.extraOptions?.find(o => o?.value?.includes('Ecken abrunden')) ? 10 : 0;
    const d = new Drawing();

    // set units and add layer for the cutting
    d.setUnits('Millimeters');
    d.addLayer('zuschnitt', 0, 'CONTINUOUS');
    d.setActiveLayer('zuschnitt');

    // Draw lines for a rectangle
    d.drawLine(0, cornerRadius, 0, width - cornerRadius); // Left line
    d.drawLine(cornerRadius, 0, length - cornerRadius, 0); // Bottom line
    d.drawLine(length, cornerRadius, length, width - cornerRadius); // Right line
    d.drawLine(cornerRadius, width, length - cornerRadius, width); // Top line

    if (item.extraOptions?.find(el => el?.value.includes('Schliff an der Breite entlang'))) {
        // draw an arrow to the right of the rectangle for the direction of the grinding
        const arrowLength = Math.max(width / 8, 10); // half of the arrow length
        const arrowWidth = 5;
        const arrowX = Math.max(length - 20, width / 2); // 20mm from the edge or middle of plate
        const arrowY = width / 2; // middle of the width
        d.drawLine(arrowX, arrowY - arrowLength, arrowX, arrowY + arrowLength);
        // draw the arrow heads width = 5mm
        d.drawLine(arrowX, arrowY - arrowLength, arrowX - arrowWidth, arrowY - arrowLength + arrowWidth);
        d.drawLine(arrowX, arrowY - arrowLength, arrowX + arrowWidth, arrowY - arrowLength + arrowWidth);
        d.drawLine(arrowX, arrowY + arrowLength, arrowX - arrowWidth, arrowY + arrowLength - arrowWidth);
        d.drawLine(arrowX, arrowY + arrowLength, arrowX + arrowWidth, arrowY + arrowLength - arrowWidth);
    }

    if (item.extraOptions?.find(el => el?.value.includes('Schliff an der Länge entlang'))) {
        // draw an arrow above the rectangle for the direction of the grinding
        const arrowLength = Math.max(length / 8, 10); // half of the arrow length, min 10mm
        const arrowWidth = 5;
        const arrowX = length / 2; // middle of the length
        const arrowY = Math.max(width - 20, length / 2); // 20mm from the edge or middle of plate
        d.drawLine(arrowX - arrowLength, arrowY, arrowX + arrowLength, arrowY);
        // draw the arrow heads width = 5mm
        d.drawLine(arrowX - arrowLength, arrowY, arrowX - arrowLength + arrowWidth, arrowY - arrowWidth);
        d.drawLine(arrowX - arrowLength, arrowY, arrowX - arrowLength + arrowWidth, arrowY + arrowWidth);
        d.drawLine(arrowX + arrowLength, arrowY, arrowX + arrowLength - arrowWidth, arrowY - arrowWidth);
        d.drawLine(arrowX + arrowLength, arrowY, arrowX + arrowLength - arrowWidth, arrowY + arrowWidth);
    }


    // Draw arcs if corners are rounded
    if (cornerRadius > 0) {
        d.drawArc(cornerRadius, cornerRadius, cornerRadius, 180, 270); // Top left
        d.drawArc(length - cornerRadius, cornerRadius, cornerRadius, 270, 0); // Top right
        d.drawArc(cornerRadius, width - cornerRadius, cornerRadius, 90, 180); // Bottom left
        d.drawArc(length - cornerRadius, width - cornerRadius, cornerRadius, 0, 90); // Bottom right
    }

    // Add order number text above the cutting
    d.addLayer('Auftragsnummer', 0, 'CONTINUOUS');
    d.setActiveLayer('Auftragsnummer');
    const textX = length / 2;
    const textY = width / 2;
    const textHeight = width / 10;
    d.drawText(textX, textY, textHeight, 1, item.orderNumber || 'Zuschnitt', 'center', 'middle');

    // Add drill holes with Standardabmessungen
    const drillHolesCountOption = item.extraOptions?.find(o => o?.name?.includes('Wieviele Bohrungen'))?.value || '0';
    if (drillHolesCountOption !== '0') {
        const drillHolesCount = parseInt(drillHolesCountOption);
        const screwSizeOption = item.extraOptions?.find(o => o?.name?.includes('Bohrlochdurchmesser'))?.value;
        const screwSize = screwSizeOption?.match(/\d+/)?.[0] || '0';
        const holeOffsetOption = item.extraOptions?.find(o => o?.name?.includes('Bohrungsabstand vom Rand'))?.value?.includes('Standard') ?
            '1.5' :
            item.extraOptions?.find(o => o?.name?.includes('Abstand vom Rand in cm'))?.value;
        // find the first float number in the string
        const holeOffsetString = holeOffsetOption?.match(/[+-]?\d+(?:[\.]\d+)?/)?.[0] || '0';
        const baseSideOptions = item.extraOptions?.find(o => o?.name?.includes('Bohrung an welcher Seite?'))?.value;

        const baseSide = baseSideOptions?.includes('Breite') ? 'Breite' : 'Länge';
        const holeRadius = (parseFloat(screwSize) + 0.5) / 2;
        const holeOffset = parseFloat(holeOffsetString) * 10; // distance from the edge in mm
        const drawCircles = []

        if (drillHolesCount !== 2 && drillHolesCount !== 4 && drillHolesCount !== 6) {
            return item.orderNumber + ': Bohrungen können nur in 2er, 4er oder 6er Schritten erstellt werden';
        }
        if (holeRadius === 0) {
            return item.orderNumber +': Bohrungsdurchmesser muss eine Zahl enthalten';
        }
        if (holeOffset === 0) {
            return item.orderNumber +': Bohrungsabstand vom Rand muss eine Zahl enthalten';

        }
        // Draw holes
        if (drillHolesCount >= 2) {
            // top left
            drawCircles.push({ x: holeOffset, y: width - holeOffset, r: holeRadius });
            // top right for BaseSide = Länge or default
            if (baseSide === 'Länge') drawCircles.push({ x: length - holeOffset, y: width - holeOffset, r: holeRadius });
            // bottom left for BaseSide = Breite
            if (baseSide === 'Breite') drawCircles.push({ x: holeOffset, y: holeOffset, r: holeRadius });
        }
        if (drillHolesCount >= 4) {
            // bottom right
            drawCircles.push({ x: length - holeOffset, y: holeOffset, r: holeRadius });
            // bottom left for BaseSide = Länge or default
            if (baseSide === 'Länge') drawCircles.push({ x: holeOffset, y: holeOffset, r: holeRadius });
            // top right for BaseSide = Breite
            if (baseSide === 'Breite') drawCircles.push({ x: length - holeOffset, y: width - holeOffset, r: holeRadius });
        }
        if (drillHolesCount >= 6) {
            if (baseSide === 'Länge') {
                // top middle
                drawCircles.push({ x: length / 2, y: width - holeOffset, r: holeRadius });
                // bottom middle
                drawCircles.push({ x: length / 2, y: holeOffset, r: holeRadius });
            }
            if (baseSide === 'Breite') {
                // middle left
                drawCircles.push({ x: holeOffset, y: width / 2, r: holeRadius });
                // middle right
                drawCircles.push({ x: length - holeOffset, y: width / 2, r: holeRadius });
            }
        }
        d.setActiveLayer('zuschnitt');
        drawCircles.map(c => d.drawCircle(c.x, c.y, c.r));
    }
    const individualDrillHoles = item.extraOptions?.find(o => o?.value?.includes('freie Platzierung der Bohrungen'));
    if (individualDrillHoles) {
        const screwSizeOption = item.extraOptions?.find(o => o?.name?.includes('Bohrlochdurchmesser'))?.value;
        const screwSize = screwSizeOption?.match(/\d+/)?.[0] || '0';
        const holeRadius = (parseFloat(screwSize) + 0.5) / 2;
        const drillHoles = item.extraOptions?.filter(o => o?.name?.includes('Bohrlöcher hinzufügen') && o?.value).map(o => o?.value)

        if (!drillHoles || drillHoles.length === 0) {
            return item.orderNumber +': Maße für Bohrlöcher fehlen';
        }
        if (holeRadius === 0) {
            return item.orderNumber +': Bohrungsdurchmesser muss eine Zahl enthalten';
        }
        const drawCircles = []
        for (const drillHole of drillHoles) {
            if (!drillHole || !drillHole.includes('/')) {
                return item.orderNumber +': Bohrlochmaße müssen mit einem / getrennt sein.';
            }
            const [x, y] = drillHole.split('/')
            if (!x || !y || x === null || y === null) {
                return item.orderNumber +': Bohrlochmaße müssen mit einem / getrennt sein.';
            }
            if (x.includes('mm') || y.includes('mm')) {
                return item.orderNumber +': Prüfe die Maße der Bohrlöcher, Angabe sollte in cm sein.';
            }
            // replace , with . for float conversion
            const xNumber = x.replace(',', '.');
            const yNumber = y.replace(',', '.');

            if (isNaN(parseFloat(xNumber)) || isNaN(parseFloat(yNumber))) {
                return item.orderNumber +': Bohrlochmaße müssen Zahlen enthalten';
            }

            drawCircles.push({ x: parseFloat(xNumber) * 10, y: parseFloat(yNumber) * 10 });
        }
        d.setActiveLayer('zuschnitt');
        drawCircles.map(c => d.drawCircle(c.x, c.y, holeRadius));
    }
    // TODO Kantungen

    const dxfString = d.toDxfString();
    // Save as .dxf file
    const blob = new Blob([dxfString], { type: 'application/dxf' });
    const material = item.name?.split(' - ')[0].replaceAll(' ', '');
    const strength = item.variations?.find(v => v?.includes('Stärke'))?.split(' ')[1]
    saveAs(blob, `${ item.orderNumber }-${ item.position || 1 }-${ material }-${ strength }.dxf`);
    return 'success'
};